.containerSpacing {
  @apply py-10;
}

.containerTopSpacing {
  @apply pt-10;
}

.containerTitleSpacing {
  @apply py-8;
}

.textGeneric {
  @apply self-center text-center px-4 md:px-0 text-black;
}

.titleBig {
  @extend .textGeneric;
  @apply text-[40px] md:text-[56px] font-bold;
}

.titleMedium {
  @extend .textGeneric;
  @apply text-[40px] md:text-[48px] font-normal;
}

.titleSmall {
  @apply text-[24px] text-black;
}

.descriptionBig {
  @apply text-[18px] md:text-[24px] max-w-[570px] self-center text-center mt-8 md:mt-16 px-4 md:px-0;
}

.descriptionMedium {
  @extend .textGeneric;
  @apply text-[16px] font-normal mt-4 md:mt-12;
}

.descriptionSmall {
  @apply text-[18px] font-light;
}

.socialMediaIcon {
  @apply fill-black hover:fill-gray-600 scale-75;
}

.bigTitle {
  font-weight: 600;
  color: #333333;
  font-size: 32px !important;
  line-height: 39px !important;
  text-align: center;
  justify-self: center;
}

.bigDescription {
  font-weight: 400;
  color: #a5a5a5;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center;
  margin-top: 16px;
  padding-bottom: 15px;
  max-width: 900px;
}

.smallDescription {
  font-weight: 600;
  color: #a5a5a5;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center;
  margin-top: 16px;
  padding-bottom: 15px;
  max-width: 1008px;
}

.teamButton {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 138px;
  height: 54px;
  background: #79c292;
  border-radius: 5px;
  justify-content: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-self: center;
}

.companySection {
  padding: 36px 10px;
  margin: auto;
  width: 90%;
  border-radius: 10px;

  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #333333;
  }

  .companyArea {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #333333;
    padding-top: 40px;
    max-width: 810px;
    margin: auto;
    flex-wrap: wrap;

    .companyGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .company__logo {
        object-fit: contain;
      }
      .company__title {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #333333;
        margin-top: 24px;

        .containerSpacing {
          @apply py-10;
        }

        .containerTopSpacing {
          @apply pt-10;
        }

        .containerTitleSpacing {
          @apply py-8;
        }

        .textGeneric {
          @apply self-center text-center px-4 md:px-0 text-black;
        }

        .titleBig {
          @extend .textGeneric;
          @apply text-[40px] md:text-[56px] font-bold;
        }

        .titleMedium {
          @extend .textGeneric;
          @apply text-[40px] md:text-[48px] font-normal;
        }

        .titleSmall {
          @apply text-[24px] text-black;
        }

        .descriptionBig {
          @apply text-[18px] md:text-[24px] max-w-[570px] self-center text-center mt-8 md:mt-16 px-4 md:px-0;
        }

        .descriptionMedium {
          @extend .textGeneric;
          @apply text-[16px] font-normal mt-4 md:mt-12;
        }

        .descriptionSmall {
          @apply text-[18px] font-light;
        }

        .socialMediaIcon {
          @apply fill-black hover:fill-gray-600 scale-75;
        }

        .bigTitle {
          font-weight: 600;
          color: #333333;
          font-size: 32px !important;
          line-height: 39px !important;
          text-align: center;
          justify-self: center;
        }

        .bigDescription {
          font-weight: 400;
          color: #a5a5a5;
          font-size: 20px !important;
          line-height: 24px !important;
          text-align: center;
          margin-top: 16px;
          padding-bottom: 15px;
          max-width: 900px;
        }

        .smallDescription {
          font-weight: 600;
          color: #a5a5a5;
          font-size: 14px !important;
          line-height: 17px !important;
          text-align: center;
          margin-top: 16px;
          padding-bottom: 15px;
          max-width: 1008px;
        }

        .teamButton {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 16px 48px;
          gap: 16px;
          width: 138px;
          height: 54px;
          background: #79c292;
          border-radius: 5px;
          flex: none;
          order: 1;
          flex-grow: 0;
          justify-self: center;
        }

        .companySection {
          padding: 10px 10px;
          margin: auto;
          width: 90%;
          border-radius: 10px;

          &__title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            color: #333333;
          }

          .companyArea {
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            color: #333333;
            padding-top: 40px;
            max-width: 810px;
            margin: auto;
            padding-right: 45px;

            .companyGroup {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100px;

              .company__title {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #333333;
                margin-top: 48px;
              }
            }

            @apply md:pr-[0px] flex;
          }

          @apply md:w-full md:border-r-0 md:py-[55px];
        }
      }
      width: calc(50% - 50px);

      @screen md {
        width: calc(25% - 50px);
      }
    }

    @apply md:pr-[0px] flex justify-around md:justify-center gap-y-[50px] md:flex-wrap gap-[50px];
  }

  @apply md:w-full md:border-r-0 md:py-[55px];
}
