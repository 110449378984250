
.homepageCardsContainer {
  //min-height: 70vh;
  //height: 500px;
  //@screen md {
  //  height: 710px;
  //}


  --bmMarginTop: 2rem;
  --bmMarginBottom: 0px;

  --bMarginTop: 4rem;
  --bMarginBottom: 0px;

  margin-top: var(--bmMarginTop);
  margin-bottom: var(--bmMarginBottom);
  height: var(--bmHeight);

  gap: 3rem;

  @screen lg {
    gap: unset;
    margin-top: var(--bMarginTop);
    margin-bottom: var(--bMarginBottom);
    height: var(--bHeight);
  }

  @apply flex flex-col md:flex-row rounded-xl md:rounded-3xl lg:rounded-3xl
}

.homepageCard {

  --bmHeight: 500px;
  --bmMarginTop: 3rem;
  --bmMarginBottom: 0px;

  --bHeight: 500px;
  --bMarginTop: 4rem;
  --bMarginBottom: 0px;

  margin-top: var(--bmMarginTop);
  margin-bottom: var(--bmMarginBottom);
  height: var(--bmHeight);

  @screen lg {
    margin-top: var(--bMarginTop);
    margin-bottom: var(--bMarginBottom);
    height: var(--bHeight);
  }

  &:hover img {
    @apply origin-bottom scale-105 duration-1000
  }

  &:hover .cardLinkButton {
    background-color: #528FCE;
    color: white !important;
  }

  @apply relative overflow-hidden flex items-end rounded-xl md:rounded-3xl lg:rounded-3xl
}

.cardImg {
  height: auto;

  width: var(--bmImgHeight);
  max-width: var(--bmImgMaxHeight);
  left: var(--bmImgLeft);
  right: var(--bmImgRight);
  top: var(--bmImgTop);
  bottom: var(--bmImgBottom);
  margin: var(--bmImgMargin);
  position: var(--bmImgPosition);
  transform: var(--bmImgTransform);

  @screen lg {
    width: var(--bImgHeight);
    max-width: var(--bImgMaxHeight);
    left: var(--bImgLeft);
    right: var(--bImgRight);
    top: var(--bImgTop);
    bottom: var(--bImgBottom);
    margin: var(--bImgMargin);
    position: var(--bImgPosition);
    transform: var(--bImgTransform);
  }
}

.cardText {

  font-size: var(--bmTextSize);
  font-weight: var(--bmTextWeight);
  line-height: var(--bmTextHeight);

  //@screen md {
  //  font-size: var(--btTextSize);
  //  font-weight: var(--btTextWeight);
  //  line-height: var(--btTextHeight);
  //}

  @screen lg {
    font-size: var(--bTextSize);
    font-weight: var(--bTextWeight);
    line-height: var(--bTextHeight);
  }
}

.homepageHalfCard {
  img {
    align-self: end;
  }

  &:hover img {
    @apply scale-105 duration-1000
  }

  &:hover .cardLinkButton {
    background-color: #528FCE;
    color: white !important;
  }

  height: 500px;

  @apply relative w-full overflow-hidden rounded-xl md:rounded-3xl lg:rounded-3xl flex items-end bg-black
}

.icontextTitle {
  font-size: 12px;
  line-height: 14px;
  @screen md {
    font-size: 18px;
    line-height: 21px;
  }
  color: #333333;
  font-weight: 700;
  padding-left: 0.1rem;
  padding-bottom: 0.2rem;
}

.icontextDesc {
  font-size: 12px;
  line-height: 16px;
  @screen md {
    font-size: 18px;
    line-height: 22px;
  }
  color: #333333;
  font-weight: 400;
  padding-left: 0.1rem;
}

.icontextTitleBig {
  font-size: 16px;
  line-height: 17px;
  @screen md {
    font-size: 19px;
    line-height: 22px;
  }
  color: #000000;
  font-weight: 500;
  padding-left: 0.1rem;
  padding-bottom: 0.2rem;
}

.icontextDescBig {
  font-size: 15px;
  line-height: 17px;
  @screen md {
    font-size: 19px;
    line-height: 22px;
  }
  color: #000000;
  font-weight: 400;
  padding-left: 0.1rem;
}

.icontextImg {
  height: auto;

  width: 10vw;
  @screen lg {
    width: min(5vw, 100px);
  }
}

.icontextCard {

  overflow: hidden;
  height: 100%;
  width: 100%;

  -webkit-box-shadow: 0 0 10px #33333355;
  box-shadow: 0 0 10px #33333355;

  @screen md {
    &:hover {
      transform: scale(1.05);
      -webkit-box-shadow: 0 0 15px #33333333;
      box-shadow: 0 0 15px #33333333;
    }
  }

  @apply rounded-3xl self-center px-6 py-3 flex flex-col
}

.icontextContainer {

  display: flex;
  justify-content: center;
  //@screen md{
  //  div{
  //   @apply mx-4;
  //  }
  //}

  @apply my-3 md:my-4 px-2 md:px-4
}

.mediaCard {

  overflow: hidden;
  height: 100%;
  width: 100%;

  -webkit-box-shadow: 0 0 10px #33333355;
  box-shadow: 0 0 10px #33333355;

  @screen md {
    &:hover {
      transform: scale(1.05);
      -webkit-box-shadow: 0 0 15px #33333333;
      box-shadow: 0 0 15px #33333333;
    }
  }

  @apply rounded-3xl self-center flex flex-col
}

.mediaImg {
  height: auto;
}

.mediaTitle {
  font-size: 18px;
  line-height: 26px;
  @screen md {
    font-size: 24px;
    line-height: 30px;
  }

  align-items: center;
  text-align: justify;
  font-weight: 500;
  color: #333333;
  padding-left: 0.2rem;
}

.mediaSource {
  font-size: 12px;
  line-height: 24px;
  @screen md {
    font-size: 18px;
    line-height: 36px;
  }

  align-items: center;
  text-align: justify;
  color: #333333;
  font-weight: 400;
  padding-left: 0.2rem;
}

.mediaDate {
  font-size: 12px;
  line-height: 24px;
  @screen md {
    font-size: 18px;
    line-height: 36px;
  }

  align-items: center;
  text-align: justify;
  color: #616161;
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-left: 0.2rem;
}

.icontextlg1 {
  @screen lg {
    flex: 0 0 100%;
  }
}

.icontextsm1 {
  flex: 0 0 100%;
}

.icontextlg2 {
  @screen lg {
    flex: 0 0 50%;
  }
}

.icontextsm2 {
  flex: 0 0 50%;
}

.icontextlg3 {
  @screen lg {
    flex: 0 0 33%;
  }
}

.icontextlg4 {
  @screen lg {
    flex: 0 0 25%;
  }
}

.icontextsm3 {
  flex: 0 0 33%;
}

.linkTextCard {

  overflow: hidden;

  -webkit-box-shadow: 0 0 10px #33333355;
  box-shadow: 0 0 10px #33333355;

  &:hover {
    transform: scale(1.1);
    -webkit-box-shadow: 0 0 15px #33333333;
    box-shadow: 0 0 15px #33333333;
  }

  @apply rounded-3xl self-center px-4 py-4 md:px-6 md:py-6 flex flex-col w-full h-[120px] md:w-[300px] md:h-[300px]
}

.medialinkTextCard {

  overflow: hidden;
  background-color: #F6F6F6;
  -webkit-box-shadow: 0 0 10px #33333355;
  box-shadow: 0 0 10px #33333355;

  @screen md {
    &:hover {
      transform: scale(1.1);
      -webkit-box-shadow: 0 0 15px #33333333;
      box-shadow: 0 0 15px #33333333;
    }
  }

  @apply rounded-3xl self-center flex flex-col w-full
}

.linkTextCardTitle {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  padding-left: 0.1rem;
  padding-bottom: 0.2rem;

  @screen md {
    font-size: 30px;
  }
}

.linkTextCardDesc {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  padding-left: 0.1rem;
  padding-bottom: 0.2rem;

  @screen md {
    font-size: 20px;
  }
}

.linkTextCardLink {
  @apply ml-1 h-6 md:h-5 self-center
}

.homepageCardSingle {
  @apply lg:flex-1
}

.homepageCardMiddle {
  @apply lg:flex-1 lg:mx-3
}

.homepageCardLeft {
  @apply lg:flex-1 lg:mr-3
}

.homepageCardRight {
  @apply lg:flex-1 lg:ml-3
}

.homepageFullCardImg {
  @apply h-full w-full rounded-3xl
}

.homepageHalfCardImg {
  object-fit: cover;
  @apply bg-gray-100 h-full w-full rounded-xl md:rounded-3xl lg:rounded-3xl
}

.videoCardContainer {
  //min-height: 60vh;
  //max-height: 70vh;

  //height: 500px;

  @apply flex flex-col justify-between
}

.homepageResponsiveHalfCardImg {
  @apply bg-gray-100 block md:hidden
}

.homepageBigCardLink {
  @apply flex
}

.cardLinkContainer {

  @apply flex justify-center
}

.cardLinkIcon {
  @apply ml-1 h-4 md:h-5 self-center
}

.cardLinkButton {

  color: #333;
  font-size: 16px;
  line-height: 32px;

  @screen md {
    font-size: 18px;
  }
  @screen lg {
    font-size: 20px;
  }

  &:hover {
    background-color: #528FCE;
  }

  @apply px-1 md:px-3 md:py-1 flex rounded-full
}

.card {
  @apply flex flex-col h-full w-full top-0
}

.cardTextRight {
  @apply md:right-0
}

.cardHalf {
  @apply flex flex-col w-full h-1/2 md:h-full top-0 absolute
}

.cardHalfText {
  @apply flex-col justify-start pt-6 pl-6 pr-4 md:absolute
}

.cardHalfLink {
  @apply text-white md:absolute md:bottom-4 md:left-4 md:w-full
}

.text {

  margin-left: var(--bmMarginLeft);
  margin-right: var(--bmMarginRight);
  margin-top: var(--bmMarginTop);
  margin-bottom: var(--bmMarginBottom);
  text-align: var(--bCenterTextH);

  @screen lg {
    place-content: var(--bCenterTextV);
    margin-left: var(--bMarginLeft);
    margin-right: var(--bMarginRight);
    margin-top: var(--bMarginTop);
    margin-bottom: var(--bMarginBottom);
  }

  //@screen md {
  //  margin-left: var(--btMarginLeft);
  //  margin-right: var(--btMarginRight);
  //  margin-top: var(--bmMarginTop);
  //  margin-bottom: var(--bmMarginBottom);
  //}

  @apply flex-2 flex flex-col justify-between
}

.videoPart {
  @apply flex-6 flex flex-col justify-center h-fit my-auto
}

.videoTextPart {
  @apply flex-3 flex flex-col justify-center
}

.title {
  font-size: 7vw;
  @screen md {
    font-size: 4vw;
  }
  @apply text-white text-left font-bold /*text-xl lg:text-4xl*/
  pt-4 px-2
}

.bigdescription {
  width: 290px;
  @apply text-white text-left font-light text-2xl px-2
}

.bigdescription2 {
  @apply text-white text-left font-light lg:text-lg px-2 py-2
}

.description {
  @apply text-white text-left font-light lg:text-lg px-2
}

.description2 {
  @apply text-white text-left font-light lg:text-lg px-2 py-2
}

.link {
  @apply text-white pt-8
}

.grayHeader {
  color: #616161;
  line-height: 35px;
  font-style: normal;
  font-weight: 500;

  font-size: 6vw;
  @screen md {
    font-size: 40px;
  }

  @apply text-center
}

.grayHeaderLeft {
  @apply text-gray-500 text-left text-base lg:text-lg
}

.grayBigHeader {
  @apply text-gray-500 text-center font-bold text-3xl lg:text-6xl px-2
}

.blackBoldTitle {
  @extend .blackTitle;
  font-weight: 500 !important;
  //@apply font-bold
}

.blackTitle {
  color: #333333;
  font-size: 36px;
  @screen lg {
    font-size: min(10vw, 56px);
    //font-size: 80px;
    line-height: 90px;
  }
  @apply text-center pt-8 px-2
}

.blackDescription {
  color: #333333;
  line-height: 35px;
  font-style: normal;
  font-weight: 400;

  font-size: 6vw;
  @screen md {
    font-size: 38px;
  }
  @apply text-center pt-4
}

.blackishDescription {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;

  line-height: 30px;
  font-size: 20px;

  span {
    line-height: 30px;
    @screen lg {
      line-height: 40px;
    }
  }

  @screen md {
    line-height: 45px;
    font-size: 30px;
  }
  @apply text-center pt-4 px-2
}

.heroButton {
  @apply inline-flex items-center px-4 py-2 mt-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-black hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}

.blackTitleBigLeft {
  color: #333333;
  @apply text-left font-bold text-2xl md:text-5xl pt-4
}

.whiteTitle {
  @apply text-white text-center text-4xl lg:text-6xl xl:text-8xl font-bold
}

.whiteButton {
  color: #333333;

  &:hover {
    @apply bg-gray-200
  }

  @apply self-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}

.rowReverseFlexDiv {
  @apply md:flex-row-reverse
}

.rowFlexDiv {
  @apply md:flex-row
}

.newsroomBigText {
  color: #333333;
  font-weight: bold;

  font-size: min(40px, 4vw);
  @screen lg {
    font-size: min(26px, 2vw);
  }
}

.newsroomSmText {
  color: #333333;

  font-size: min(36px, 2.8vw);
  @screen lg {
    font-size: min(20px, 1.5vw);
  }
}

.blogBigText {
  color: #333333;
  font-weight: bold;

  font-size: min(34px, 3.5vw);
  @screen lg {
    font-size: min(20px, 1.5vw);
  }
}

.blogSmText {
  color: #333333;

  line-height: 10px;
  font-size: min(26px, 3vw);
  @screen lg {
    font-size: min(16px, 1.2vw);
  }
}


.gridcolMobile1 {
  @apply grid-cols-1
}

.gridcolMobile2 {
  @apply grid-cols-2
}

.gridcolMobile3 {
  @apply grid-cols-3
}

.gridcolMobile4 {
  @apply grid-cols-4
}

.gridcolDesktop1 {
  @apply md:grid-cols-1
}

.gridcolDesktop2 {
  @apply md:grid-cols-2
}

.gridcolDesktop3 {
  @apply md:grid-cols-3
}

.gridcolDesktop4 {
  @apply md:grid-cols-4
}

.card {
  border: 1px solid #F7F7F7;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-shadow: 0px 4px 20px rgba(167, 169, 185, 0.2);
  border-radius: 12px;
  padding: 10px 20px;

  &__img {
    margin-top: 8px;
    align-self: center;
    width: 183px;
  }

  &__title {
    color: #333333;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
    align-self: center;

    @apply md:self-center
  }

  &__desc {
    color: #A5A5A5;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 16px 0px;

    @apply md:text-center
  }

  width: 320px;

  @screen sm{
    width: 200px;
  }

  @screen md{
    width: 236px;
  }

}

.cardSection {

  max-width: 1008px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px 20px;

  &__title {
    font-weight: 600;
    color: #333333;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  &__desc {
    font-weight: 400;
    color: #A5A5A5;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }

  &__area {
    padding-top: 32px;
    column-gap: 32px;
    row-gap: 48px;
    margin: auto;
    @apply grid sm:grid-cols-2 md:grid-cols-3 
  }

  &__area_2 {
    padding-top: 48px;
    column-gap: 32px;
    row-gap: 48px;
    margin: auto;
    @apply grid sm:grid-cols-2 md:grid-cols-2 
  }

}

.bannerSection {
  //width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 85px;

  justify-content: start;

  @screen md {
    justify-content: center;
    min-height: 100vh;
  }

  @apply flex flex-col-reverse md:flex-row
}

.bannerTitle {
  color: #333333;
  font-weight: 400;
  font-size: 25px;
  text-align: center;

  @screen xl {
    font-size: 48px;
    line-height: 59px;
    text-align: start;
  }
}

.bannerTitleBig {
  color: #79C292;
  font-weight: 600;
  @extend .bannerTitle;
}

.bannerDescription {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  @extend .bannerTitle;
}

.bannerImage {
  max-height: 200px;

  @screen md {
    max-height: 426px;
  }
  @apply mx-auto
}
